<template>
    <footer class="footer">
        <div id="Footer"><!--{listing:1813129453}--><!--count:1/2147483647-->
            <div class="link">
                <h4 style="float:left;">友情链接：</h4>
                <ul>
                    <li><a href="http://www.gov.cn/" target="_blank">中国政府网</a></li>
                    <li><a href="http://www.mof.gov.cn/" target="_blank">财政部</a></li>
                    <li><a href="http://www.mot.gov.cn/" target="_blank">交通运输部</a></li>
                    <li style="border:0;"><a href="http://www.spb.gov.cn/" target="_blank">国家邮政局</a></li>
                </ul>
            </div>
            <div class="foot w1200"><span id="NodeText">
                    <p>中国邮政集团有限公司版权所有<a href="http://beian.miit.gov.cn" target="_blank">京ICP备15035540号</a><a
                            href="https://beian.mps.gov.cn/#/query/webSearch?code=11010202010949" rel="noreferrer"
                            target="_blank"><img
                                src="https://www.chinapost.com.cn/res/ChinaPostJT/structure/240913560.png"
                                style="margin: 0 5px;width: 19px;">京公网安备11010202010949</a><a
                            href="/html1/folder/181428/8737-1.htm">联系我们</a></p>
                    <p></p>
                </span></div><!--{section:1814289473}--><span style="display:none;">
            </span>
        </div><!--{section:1814289473}--><span style="display:none;">
            <span id="cnzz_stat_icon_1253166002"></span>
        </span>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterView'
}
</script>

<style scoped>
.foot {
    border-top: 1px solid #EAEAEE;
    text-align: center;
}

.foot p {
    font-size: 12px;
    color: #8E8E92;
    line-height: 38px;
}

.foot p a {
    margin-left: 30px;
    color: #8E8E92;
}

.link {
    width: 530px;
    margin: auto;
    padding: 20px 0;
    text-align: center;
    display: block;
    overflow: hidden;
}

.link ul {
    float: left;
}

.link ul li {
    padding: 0px 20px;
    font-size: 14px;
    color: #2E2E30;
    border-right: 1px solid #D8D8DC;
    float: left;
}

.link ul li a {
    color: #2E2E30;
}

h4 {
    font-size: 16px;
    color: #8E8E92;
}

img {
    vertical-align: middle;
}

/* #Footer {
    background: #F6F6FA;
} */
</style>